html {
    scroll-padding-top: 150px;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text-color);
    background-color: var(--bg-color);
}

h1, h2, h3, h4 {
    margin: 0;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: var(--text-color);
}

ul {
    list-style: none;
    padding-left: 0px;
    margin: 0;
}

img {
    max-width: 100%;
}

button {
    font-family: var(--font-family);
    padding: 0;
    cursor: pointer;
}

input {
    font-family: var(--font-family);
}

::placeholder {
    font-family: var(--font-family);
}

::-webkit-scrollbar {
    width: 16px;
}

::-webkit-scrollbar-track {
    // background: red;
    background: transparent;
    // margin-block: 100px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba($color: #00b9ff, $alpha: 0.75);
}