.aboutMe {
    width: 1246px;
    .title {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        h1 {
            font-size: 45px;
            color: var(--primary);
            font-weight: 600;
            margin-right: 8px;
        }

        span {
            border: 1px solid var(--primary);
            width: 500px;
            height: 0;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        p {
            font-size: 23px;
            font-weight: 400;
            width: 726px;
            margin: 0;
        }

        img {
            width: 400px;
            border-radius: 25px;
        }
    }
}