.skills {
    width: 1246px;
    .title {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        h1 {
            font-size: 45px;
            color: var(--primary);
            font-weight: 600;
            margin-right: 8px;
        }

        span {
            border: 1px solid var(--primary);
            width: 500px;
            height: 0;
        }
    }

    .content {
        .tabsLink {
            display: flex;
            margin-bottom: 32px;
            h2 {
                font-size: 32px;
                color: rgba($color: #c5c6c7, $alpha: 0.75);
                font-weight: 600;
                margin-right: 64px;
                text-decoration: underline transparent;
                cursor: pointer;
                transition: all 0.2s;
                &:not(.selected):hover {
                    color: var(--text-color);
                    text-decoration: underline;
                }
            }

            .selected {
                color: rgba($color: #00b9ff, $alpha: 0.75);
                text-decoration: underline;
            }
        }
    }
}