.projectTag {
    margin-left: 32px;
    padding: 16px;
    font-size: 24px;
    color: var(--bg-color);
    border-radius: 25px;
}

.react {
    background-color: #61DAFB;
}

.javascript {
    background-color: #F7E018;
}

.html {
    background-color: #eb5a25;
}

.css {
    background-color: #264DE4;
}

.csharp {
    background-color: #690081;
}

.figma {
    background: linear-gradient(276.79deg, #a259ff 0%, #0acf83 94.62%);
}

.unity {
    background: linear-gradient(276.79deg, #FF0000 0%, #7CFC00 94.62%);
}