.backspaceBtn {
    margin: 16px;
    background-color: transparent;
    border-radius: 100px;
    border: none;
    width: 58px;
    height: 58px;
    path {
        fill: var(--primary);
        stroke: var(--primary);
    }
}

.projectTitle {
    margin: -58px 0 64px;
    font-size: 64px;
    font-weight: 700;
    color: var(--primary);
    text-align: center;
}

.projectInfo {
    display: flex;

    align-items: center;

    .projectImage {
        margin: 0 64px 0 64px;
        width: 548.58px;
        height: 308.5px;
        border-radius: 25px;
        object-fit: cover;
        object-position: center top;
    }

    .projectInfoDetails {

        p {
            font-size: 32px;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 16px;

            .projectDate {
                color: var(--primary);
            }
        }

        .projectMakeWith {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}

.projectDescription {
    h2 {
        font-size: 45px;
        font-weight: 600;
        text-align: center;
        margin: 64px 0;
    }
    
    p {
        margin: 0;
        margin-bottom: 32px;
        padding: 0 64px;
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
    }
}